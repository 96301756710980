.odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-wrapper-w1p2378g{box-shadow:0px 6px 15px 2px rgba(16,24,40,0.06),0px 2px 10px -2px rgba(16,24,40,0.1);border-radius:12px;cursor:pointer;padding:1.5rem 2rem;-webkit-transition:all 0.25s ease;transition:all 0.25s ease;gap:2.5rem;height:100%;}.odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-wrapper-w1p2378g .btn-link{font-size:0.75rem;}.theme-light .odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-wrapper-w1p2378g:hover{background:#F3F3F8;box-shadow:none;}.theme-dark .odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-wrapper-w1p2378g:hover{background:#2c2c2c;box-shadow:none;}@media (max-width:799.98px){.odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-wrapper-w1p2378g{-webkit-flex-direction:column-reverse;-ms-flex-direction:column-reverse;flex-direction:column-reverse;}}
.odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-cardTitle-cc4773{font-size:1.125rem;line-height:1.75rem;font-weight:600;margin-top:0.75rem;margin-bottom:0.5rem;}.theme-light .odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-cardTitle-cc4773{color:#004EEB;}.theme-dark .odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-cardTitle-cc4773{color:#004EEB;}
.odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-cardDescription-c1i4t87k{font-size:0.875rem;line-height:1.25rem;font-weight:400;margin-bottom:1rem;}.theme-light .odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-cardDescription-c1i4t87k{color:#667085;}.theme-dark .odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-cardDescription-c1i4t87k{color:#667085;}@media (max-width:799.98px){.odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-cardDescription-c1i4t87k{margin-bottom:0.5rem;}}
@media (max-width:799.98px){.odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-cardThumbnailWrapper-cp4ds8{width:100%;}}@media (min-width:800px){.odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-cardThumbnailWrapper-cp4ds8{width:162px;height:170px;}}
.odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-cardThumbnailImage-cda3i3b{object-fit:contain;width:100%;border-radius:0.625rem;overflow:hidden;}
.odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-tag-t1efwikp{padding:4px 8px;border-radius:2px;font-weight:500;font-size:0.75rem;line-height:0.75rem;width:-webkit-max-content;width:-moz-max-content;width:max-content;}
.odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-modelTag-m1qqbslx{background-color:#f4f3ff;color:#5925dc;}
.odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-workflowTag-w13f12vw{background-color:#fffaeb;color:#b54708;}
.odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-dot-dnbeebw{width:4px;height:4px;border-radius:2px;}.theme-light .odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-dot-dnbeebw{background:#D0D5DD;}.theme-dark .odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-dot-dnbeebw{background:#D0D5DD;}
.odules__Featured__components__FeaturedEntityCard__FeaturedEntityCard_styles_tsx-footerWrapper-f1wnfkcw{gap:1rem;font-size:0.75rem;}
